@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul, li {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* iOSでのデフォルトスタイルをリセット */
input[type="submit"],
input[type="button"] {
  border-radius: 0;
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  appearance: button;
  border: none;
  box-sizing: border-box;
  cursor: pointer; }

input[type="submit"]::-webkit-search-decoration,
input[type="button"]::-webkit-search-decoration {
  display: none; }

input[type="submit"]::focus,
input[type="button"]::focus {
  outline-offset: -2px; }

body {
  font-family: "Hiragino Kaku Gothic ProN","メイリオ", sans-serif; }

img {
  max-width: 100%;
  max-height: 100%; }

.l-wrap {
  width: 640px;
  max-width: 100%;
  margin: 0 auto; }

input.text {
  border: 1px solid blue;
  width: calc(100% - 30px);
  padding: 5px;
  margin: 0 15px;
  box-sizing: border-box;
  font-size: 16px; }

.l-img {
  text-align: center;
  margin: 0 0 20px 0; }
  .l-img p {
    margin: 20px 0; }
  .l-img a {
    color: blue; }

.l-controller {
  padding: 0 20px; }

.button-prev, .button-next, .button-restart, .button-img .submit, .button-start, .button-fb, .button-tw {
  display: block;
  width: 100%;
  position: relative;
  text-align: center;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
  text-align: center;
  padding: 30px 0;
  font-size: 16px;
  border-radius: 5px;
  margin: 0 0 10px 0;
  line-height: 1.4; }
  .button-prev:before, .button-next:before, .button-restart:before, .button-img .submit:before, .button-start:before, .button-fb:before, .button-tw:before {
    position: absolute;
    top: 50%;
    font-size: 14px;
    transform: translate3D(0, -50%, 0); }
  .is-disabled.button-prev, .is-disabled.button-next, .is-disabled.button-restart, .button-img .is-disabled.submit, .is-disabled.button-start, .is-disabled.button-fb, .is-disabled.button-tw {
    pointer-events: none;
    background: silver; }
  .is-hide.button-prev, .is-hide.button-next, .is-hide.button-restart, .button-img .is-hide.submit, .is-hide.button-start, .is-hide.button-fb, .is-hide.button-tw {
    display: none; }

.button-prev:before {
  content: "◀︎";
  left: 20px; }

.button-next:before, .button-img .submit:before, .button-start:before {
  content: "▶︎";
  right: 20px; }

.button-prev {
  background: gray; }

.button-next {
  background: blue; }

.button-restart {
  background: gray;
  font-size: 13px; }

.button-img .submit {
  width: 100%;
  background: blue;
  font-size: 13px; }

.button-img.is-hide {
  display: none; }

.button-start {
  background: blue; }

.l-controller-sns {
  display: flex;
  padding: 0 10px; }

.button-fb {
  width: 100%;
  background: #4267b2;
  margin: 0 5px; }

.button-tw {
  width: 100%;
  background: #1b95e0;
  margin: 0 5px; }

.field .g, .field .d, .field .m, .field .f {
  position: absolute;
  width: 15px;
  height: 15px;
  background-size: 15px auto;
  background-position: 0 0;
  background-repeat: no-repeat; }

.field .g.is-current, .field .d.is-current, .field .m.is-current, .field .f.is-current {
  height: 35px; }
  .field .g.is-current:after, .field .d.is-current:after, .field .m.is-current:after, .field .f.is-current:after {
    content: "";
    display: block;
    width: 100%;
    height: 20px;
    padding-top: 15px;
    background-image: url(../img/formation/items/arrow.png);
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 15px auto; }

.field .d_endo, .field .d_makino, .field .d_nagatomo, .field .d_sakai_g, .field .d_sakai_h, .field .d_syoji, .field .d_ueda, .field .d_yoshida, .field .f_muto, .field .f_okazaki, .field .f_osako, .field .g_higashiguchi, .field .g_kawashima, .field .g_nakamura, .field .m_haraguchi, .field .m_hasebe, .field .m_honda, .field .m_inui, .field .m_kagawa, .field .m_oshima, .field .m_shibazaki, .field .m_usami, .field .m_yamaguchi, .field .else {
  width: 50px;
  height: 18px;
  background-size: 50px auto; }

.field {
  position: relative;
  width: 300px;
  height: 200px;
  margin: 0 auto;
  background-image: url(../img/formation/items/field.jpg);
  background-size: 300px auto;
  background-repeat: no-repeat; }
  .field .g {
    background-image: url(../img/formation/items/g.png);
    top: 94px;
    left: 20px; }
    .field .g.is-current {
      background-image: url(../img/formation/items/g_current.png); }
  .field .d {
    background-image: url(../img/formation/items/d.png); }
    .field .d.is-current {
      background-image: url(../img/formation/items/d_current.png); }
  .field .m {
    background-image: url(../img/formation/items/m.png); }
    .field .m.is-current {
      background-image: url(../img/formation/items/m_current.png); }
  .field .f {
    background-image: url(../img/formation/items/f.png); }
    .field .f.is-current {
      background-image: url(../img/formation/items/f_current.png); }
  .field .d_endo {
    background-image: url(../img/player/d_endo.png) !important; }
    .field .d_endo.g {
      transform: translate3d(-25px, -5px, 0); }
    .field .d_endo.d {
      transform: translate3d(-15px, -5px, 0); }
      .field .d_endo.d .section-4231,
      .section-4141 .field .d_endo.d {
        transform: translate3d(-25px, -5px, 0); }
    .field .d_endo.m {
      transform: translate3d(-10px, -5px, 0); }
    .field .d_endo.f {
      transform: translate3d(0px, -5px, 0); }
  .field .d_makino {
    background-image: url(../img/player/d_makino.png) !important; }
    .field .d_makino.g {
      transform: translate3d(-25px, -5px, 0); }
    .field .d_makino.d {
      transform: translate3d(-15px, -5px, 0); }
      .field .d_makino.d .section-4231,
      .section-4141 .field .d_makino.d {
        transform: translate3d(-25px, -5px, 0); }
    .field .d_makino.m {
      transform: translate3d(-10px, -5px, 0); }
    .field .d_makino.f {
      transform: translate3d(0px, -5px, 0); }
  .field .d_nagatomo {
    background-image: url(../img/player/d_nagatomo.png) !important; }
    .field .d_nagatomo.g {
      transform: translate3d(-25px, -5px, 0); }
    .field .d_nagatomo.d {
      transform: translate3d(-15px, -5px, 0); }
      .field .d_nagatomo.d .section-4231,
      .section-4141 .field .d_nagatomo.d {
        transform: translate3d(-25px, -5px, 0); }
    .field .d_nagatomo.m {
      transform: translate3d(-10px, -5px, 0); }
    .field .d_nagatomo.f {
      transform: translate3d(0px, -5px, 0); }
  .field .d_sakai_g {
    background-image: url(../img/player/d_sakai_g.png) !important; }
    .field .d_sakai_g.g {
      transform: translate3d(-25px, -5px, 0); }
    .field .d_sakai_g.d {
      transform: translate3d(-15px, -5px, 0); }
      .field .d_sakai_g.d .section-4231,
      .section-4141 .field .d_sakai_g.d {
        transform: translate3d(-25px, -5px, 0); }
    .field .d_sakai_g.m {
      transform: translate3d(-10px, -5px, 0); }
    .field .d_sakai_g.f {
      transform: translate3d(0px, -5px, 0); }
  .field .d_sakai_h {
    background-image: url(../img/player/d_sakai_h.png) !important; }
    .field .d_sakai_h.g {
      transform: translate3d(-25px, -5px, 0); }
    .field .d_sakai_h.d {
      transform: translate3d(-15px, -5px, 0); }
      .field .d_sakai_h.d .section-4231,
      .section-4141 .field .d_sakai_h.d {
        transform: translate3d(-25px, -5px, 0); }
    .field .d_sakai_h.m {
      transform: translate3d(-10px, -5px, 0); }
    .field .d_sakai_h.f {
      transform: translate3d(0px, -5px, 0); }
  .field .d_syoji {
    background-image: url(../img/player/d_syoji.png) !important; }
    .field .d_syoji.g {
      transform: translate3d(-25px, -5px, 0); }
    .field .d_syoji.d {
      transform: translate3d(-15px, -5px, 0); }
      .field .d_syoji.d .section-4231,
      .section-4141 .field .d_syoji.d {
        transform: translate3d(-25px, -5px, 0); }
    .field .d_syoji.m {
      transform: translate3d(-10px, -5px, 0); }
    .field .d_syoji.f {
      transform: translate3d(0px, -5px, 0); }
  .field .d_ueda {
    background-image: url(../img/player/d_ueda.png) !important; }
    .field .d_ueda.g {
      transform: translate3d(-25px, -5px, 0); }
    .field .d_ueda.d {
      transform: translate3d(-15px, -5px, 0); }
      .field .d_ueda.d .section-4231,
      .section-4141 .field .d_ueda.d {
        transform: translate3d(-25px, -5px, 0); }
    .field .d_ueda.m {
      transform: translate3d(-10px, -5px, 0); }
    .field .d_ueda.f {
      transform: translate3d(0px, -5px, 0); }
  .field .d_yoshida {
    background-image: url(../img/player/d_yoshida.png) !important; }
    .field .d_yoshida.g {
      transform: translate3d(-25px, -5px, 0); }
    .field .d_yoshida.d {
      transform: translate3d(-15px, -5px, 0); }
      .field .d_yoshida.d .section-4231,
      .section-4141 .field .d_yoshida.d {
        transform: translate3d(-25px, -5px, 0); }
    .field .d_yoshida.m {
      transform: translate3d(-10px, -5px, 0); }
    .field .d_yoshida.f {
      transform: translate3d(0px, -5px, 0); }
  .field .f_muto {
    background-image: url(../img/player/f_muto.png) !important; }
    .field .f_muto.g {
      transform: translate3d(-25px, -5px, 0); }
    .field .f_muto.d {
      transform: translate3d(-15px, -5px, 0); }
      .field .f_muto.d .section-4231,
      .section-4141 .field .f_muto.d {
        transform: translate3d(-25px, -5px, 0); }
    .field .f_muto.m {
      transform: translate3d(-10px, -5px, 0); }
    .field .f_muto.f {
      transform: translate3d(0px, -5px, 0); }
  .field .f_okazaki {
    background-image: url(../img/player/f_okazaki.png) !important; }
    .field .f_okazaki.g {
      transform: translate3d(-25px, -5px, 0); }
    .field .f_okazaki.d {
      transform: translate3d(-15px, -5px, 0); }
      .field .f_okazaki.d .section-4231,
      .section-4141 .field .f_okazaki.d {
        transform: translate3d(-25px, -5px, 0); }
    .field .f_okazaki.m {
      transform: translate3d(-10px, -5px, 0); }
    .field .f_okazaki.f {
      transform: translate3d(0px, -5px, 0); }
  .field .f_osako {
    background-image: url(../img/player/f_osako.png) !important; }
    .field .f_osako.g {
      transform: translate3d(-25px, -5px, 0); }
    .field .f_osako.d {
      transform: translate3d(-15px, -5px, 0); }
      .field .f_osako.d .section-4231,
      .section-4141 .field .f_osako.d {
        transform: translate3d(-25px, -5px, 0); }
    .field .f_osako.m {
      transform: translate3d(-10px, -5px, 0); }
    .field .f_osako.f {
      transform: translate3d(0px, -5px, 0); }
  .field .g_higashiguchi {
    background-image: url(../img/player/g_higashiguchi.png) !important; }
    .field .g_higashiguchi.g {
      transform: translate3d(-25px, -5px, 0); }
    .field .g_higashiguchi.d {
      transform: translate3d(-15px, -5px, 0); }
      .field .g_higashiguchi.d .section-4231,
      .section-4141 .field .g_higashiguchi.d {
        transform: translate3d(-25px, -5px, 0); }
    .field .g_higashiguchi.m {
      transform: translate3d(-10px, -5px, 0); }
    .field .g_higashiguchi.f {
      transform: translate3d(0px, -5px, 0); }
  .field .g_kawashima {
    background-image: url(../img/player/g_kawashima.png) !important; }
    .field .g_kawashima.g {
      transform: translate3d(-25px, -5px, 0); }
    .field .g_kawashima.d {
      transform: translate3d(-15px, -5px, 0); }
      .field .g_kawashima.d .section-4231,
      .section-4141 .field .g_kawashima.d {
        transform: translate3d(-25px, -5px, 0); }
    .field .g_kawashima.m {
      transform: translate3d(-10px, -5px, 0); }
    .field .g_kawashima.f {
      transform: translate3d(0px, -5px, 0); }
  .field .g_nakamura {
    background-image: url(../img/player/g_nakamura.png) !important; }
    .field .g_nakamura.g {
      transform: translate3d(-25px, -5px, 0); }
    .field .g_nakamura.d {
      transform: translate3d(-15px, -5px, 0); }
      .field .g_nakamura.d .section-4231,
      .section-4141 .field .g_nakamura.d {
        transform: translate3d(-25px, -5px, 0); }
    .field .g_nakamura.m {
      transform: translate3d(-10px, -5px, 0); }
    .field .g_nakamura.f {
      transform: translate3d(0px, -5px, 0); }
  .field .m_haraguchi {
    background-image: url(../img/player/m_haraguchi.png) !important; }
    .field .m_haraguchi.g {
      transform: translate3d(-25px, -5px, 0); }
    .field .m_haraguchi.d {
      transform: translate3d(-15px, -5px, 0); }
      .field .m_haraguchi.d .section-4231,
      .section-4141 .field .m_haraguchi.d {
        transform: translate3d(-25px, -5px, 0); }
    .field .m_haraguchi.m {
      transform: translate3d(-10px, -5px, 0); }
    .field .m_haraguchi.f {
      transform: translate3d(0px, -5px, 0); }
  .field .m_hasebe {
    background-image: url(../img/player/m_hasebe.png) !important; }
    .field .m_hasebe.g {
      transform: translate3d(-25px, -5px, 0); }
    .field .m_hasebe.d {
      transform: translate3d(-15px, -5px, 0); }
      .field .m_hasebe.d .section-4231,
      .section-4141 .field .m_hasebe.d {
        transform: translate3d(-25px, -5px, 0); }
    .field .m_hasebe.m {
      transform: translate3d(-10px, -5px, 0); }
    .field .m_hasebe.f {
      transform: translate3d(0px, -5px, 0); }
  .field .m_honda {
    background-image: url(../img/player/m_honda.png) !important; }
    .field .m_honda.g {
      transform: translate3d(-25px, -5px, 0); }
    .field .m_honda.d {
      transform: translate3d(-15px, -5px, 0); }
      .field .m_honda.d .section-4231,
      .section-4141 .field .m_honda.d {
        transform: translate3d(-25px, -5px, 0); }
    .field .m_honda.m {
      transform: translate3d(-10px, -5px, 0); }
    .field .m_honda.f {
      transform: translate3d(0px, -5px, 0); }
  .field .m_inui {
    background-image: url(../img/player/m_inui.png) !important; }
    .field .m_inui.g {
      transform: translate3d(-25px, -5px, 0); }
    .field .m_inui.d {
      transform: translate3d(-15px, -5px, 0); }
      .field .m_inui.d .section-4231,
      .section-4141 .field .m_inui.d {
        transform: translate3d(-25px, -5px, 0); }
    .field .m_inui.m {
      transform: translate3d(-10px, -5px, 0); }
    .field .m_inui.f {
      transform: translate3d(0px, -5px, 0); }
  .field .m_kagawa {
    background-image: url(../img/player/m_kagawa.png) !important; }
    .field .m_kagawa.g {
      transform: translate3d(-25px, -5px, 0); }
    .field .m_kagawa.d {
      transform: translate3d(-15px, -5px, 0); }
      .field .m_kagawa.d .section-4231,
      .section-4141 .field .m_kagawa.d {
        transform: translate3d(-25px, -5px, 0); }
    .field .m_kagawa.m {
      transform: translate3d(-10px, -5px, 0); }
    .field .m_kagawa.f {
      transform: translate3d(0px, -5px, 0); }
  .field .m_oshima {
    background-image: url(../img/player/m_oshima.png) !important; }
    .field .m_oshima.g {
      transform: translate3d(-25px, -5px, 0); }
    .field .m_oshima.d {
      transform: translate3d(-15px, -5px, 0); }
      .field .m_oshima.d .section-4231,
      .section-4141 .field .m_oshima.d {
        transform: translate3d(-25px, -5px, 0); }
    .field .m_oshima.m {
      transform: translate3d(-10px, -5px, 0); }
    .field .m_oshima.f {
      transform: translate3d(0px, -5px, 0); }
  .field .m_shibazaki {
    background-image: url(../img/player/m_shibazaki.png) !important; }
    .field .m_shibazaki.g {
      transform: translate3d(-25px, -5px, 0); }
    .field .m_shibazaki.d {
      transform: translate3d(-15px, -5px, 0); }
      .field .m_shibazaki.d .section-4231,
      .section-4141 .field .m_shibazaki.d {
        transform: translate3d(-25px, -5px, 0); }
    .field .m_shibazaki.m {
      transform: translate3d(-10px, -5px, 0); }
    .field .m_shibazaki.f {
      transform: translate3d(0px, -5px, 0); }
  .field .m_usami {
    background-image: url(../img/player/m_usami.png) !important; }
    .field .m_usami.g {
      transform: translate3d(-25px, -5px, 0); }
    .field .m_usami.d {
      transform: translate3d(-15px, -5px, 0); }
      .field .m_usami.d .section-4231,
      .section-4141 .field .m_usami.d {
        transform: translate3d(-25px, -5px, 0); }
    .field .m_usami.m {
      transform: translate3d(-10px, -5px, 0); }
    .field .m_usami.f {
      transform: translate3d(0px, -5px, 0); }
  .field .m_yamaguchi {
    background-image: url(../img/player/m_yamaguchi.png) !important; }
    .field .m_yamaguchi.g {
      transform: translate3d(-25px, -5px, 0); }
    .field .m_yamaguchi.d {
      transform: translate3d(-15px, -5px, 0); }
      .field .m_yamaguchi.d .section-4231,
      .section-4141 .field .m_yamaguchi.d {
        transform: translate3d(-25px, -5px, 0); }
    .field .m_yamaguchi.m {
      transform: translate3d(-10px, -5px, 0); }
    .field .m_yamaguchi.f {
      transform: translate3d(0px, -5px, 0); }
  .field .else {
    background-image: url(../img/player/else.png) !important; }
    .field .else.g {
      transform: translate3d(-25px, -5px, 0); }
    .field .else.d {
      transform: translate3d(-15px, -5px, 0); }
      .field .else.d .section-4231,
      .section-4141 .field .else.d {
        transform: translate3d(-25px, -5px, 0); }
    .field .else.m {
      transform: translate3d(-10px, -5px, 0); }
    .field .else.f {
      transform: translate3d(0px, -5px, 0); }
  .section-442 .field .d1 {
    top: 24px;
    left: 75px; }
  .section-442 .field .d2 {
    top: 66px;
    left: 63px; }
  .section-442 .field .d3 {
    top: 122px;
    left: 63px; }
  .section-442 .field .d4 {
    top: 163px;
    left: 75px; }
  .section-442 .field .m1 {
    top: 18px;
    left: 140px; }
  .section-442 .field .m2 {
    top: 68px;
    left: 123px; }
  .section-442 .field .m3 {
    top: 123px;
    left: 123px; }
  .section-442 .field .m4 {
    top: 169px;
    left: 140px; }
  .section-442 .field .f1 {
    top: 73px;
    left: 190px; }
  .section-442 .field .f2 {
    top: 118px;
    left: 190px; }
  .section-4141 .field .d1 {
    top: 24px;
    left: 65px; }
  .section-4141 .field .d2 {
    top: 66px;
    left: 63px; }
  .section-4141 .field .d3 {
    top: 122px;
    left: 63px; }
  .section-4141 .field .d4 {
    top: 163px;
    left: 65px; }
  .section-4141 .field .m1 {
    top: 94px;
    left: 110px; }
  .section-4141 .field .m2 {
    top: 18px;
    left: 177px; }
  .section-4141 .field .m3 {
    top: 63px;
    left: 163px; }
  .section-4141 .field .m4 {
    top: 128px;
    left: 163px; }
  .section-4141 .field .m5 {
    top: 169px;
    left: 177px; }
  .section-4141 .field .f1 {
    top: 94px;
    left: 202px; }
  .section-4231 .field .d1 {
    top: 24px;
    left: 80px; }
  .section-4231 .field .d2 {
    top: 76px;
    left: 73px; }
  .section-4231 .field .d3 {
    top: 112px;
    left: 73px; }
  .section-4231 .field .d4 {
    top: 163px;
    left: 80px; }
  .section-4231 .field .m1 {
    top: 64px;
    left: 115px; }
  .section-4231 .field .m2 {
    top: 124px;
    left: 115px; }
  .section-4231 .field .m3 {
    top: 18px;
    left: 167px; }
  .section-4231 .field .m4 {
    top: 94px;
    left: 158px; }
  .section-4231 .field .m5 {
    top: 169px;
    left: 167px; }
  .section-4231 .field .f1 {
    top: 94px;
    left: 202px; }
  .section-361 .field .d1 {
    top: 42px;
    left: 62px; }
  .section-361 .field .d2 {
    top: 95px;
    left: 62px; }
  .section-361 .field .d3 {
    top: 145px;
    left: 62px; }
  .section-361 .field .m1 {
    top: 67px;
    left: 104px; }
  .section-361 .field .m2 {
    top: 122px;
    left: 104px; }
  .section-361 .field .m3 {
    top: 25px;
    left: 138px; }
  .section-361 .field .m4 {
    top: 160px;
    left: 138px; }
  .section-361 .field .m5 {
    top: 60px;
    left: 167px; }
  .section-361 .field .m6 {
    top: 120px;
    left: 167px; }
  .section-361 .field .f1 {
    top: 94px;
    left: 202px; }
  .section-361 .field .d1 {
    top: 42px;
    left: 62px; }
  .section-361 .field .d2 {
    top: 95px;
    left: 62px; }
  .section-361 .field .d3 {
    top: 145px;
    left: 62px; }
  .section-361 .field .m1 {
    top: 67px;
    left: 104px; }
  .section-361 .field .m2 {
    top: 122px;
    left: 104px; }
  .section-361 .field .m3 {
    top: 25px;
    left: 138px; }
  .section-361 .field .m4 {
    top: 160px;
    left: 138px; }
  .section-361 .field .m5 {
    top: 60px;
    left: 167px; }
  .section-361 .field .m6 {
    top: 120px;
    left: 167px; }
  .section-361 .field .f1 {
    top: 94px;
    left: 202px; }
  .section-352 .field .d1 {
    top: 42px;
    left: 62px; }
  .section-352 .field .d2 {
    top: 95px;
    left: 62px; }
  .section-352 .field .d3 {
    top: 145px;
    left: 62px; }
  .section-352 .field .m1 {
    top: 67px;
    left: 104px; }
  .section-352 .field .m2 {
    top: 122px;
    left: 104px; }
  .section-352 .field .m3 {
    top: 25px;
    left: 138px; }
  .section-352 .field .m4 {
    top: 160px;
    left: 138px; }
  .section-352 .field .m5 {
    top: 95px;
    left: 167px; }
  .section-352 .field .f1 {
    top: 58px;
    left: 202px; }
  .section-352 .field .f2 {
    top: 130px;
    left: 202px; }

.l-footer {
  font-size: 12px;
  padding: 40px 0;
  text-align: center;
  color: gray; }
  .l-footer a {
    color: blue; }
  .l-footer p {
    margin-bottom: 10px; }

.list-formation {
  display: flex;
  margin: 20px 0; }
  .list-formation li {
    margin: 0 10px;
    width: calc(100% / 3 - 20px); }
    .list-formation li a {
      display: block;
      text-decoration: none;
      color: #000;
      font-size: 13px; }
      .list-formation li a img {
        border: 3px solid transparent;
        box-sizing: border-box; }
      .list-formation li a.is-selected img {
        border: 3px solid blue; }
    .list-formation li span {
      display: block;
      margin: 0 0 15px 0;
      text-align: center;
      font-weight: bold; }

.l-header {
  margin: 0 auto;
  text-align: center;
  padding: 40px 0; }
  .l-header h1 {
    line-height: 0;
    width: 240px;
    margin: 0 auto; }

.section-heading {
  text-align: center;
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 15px; }

.section-4231,
.section-361,
.section-352,
.section-4141,
.section-442 {
  display: none; }

.section-inner {
  margin-bottom: 20px; }

.select {
  width: calc(100% - 30px);
  height: 40px;
  border: 1px solid blue;
  color: blue;
  font-size: 20px;
  font-weight: bold;
  margin: 15px; }

#share ul {
  padding: 20px 0;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center; }

#share li {
  font-size: 14px;
  text-align: center;
  width: 84px;
  margin-right: 1%;
  margin-bottom: 8px;
  border-radius: 3px;
  list-style: none; }

#share li.share-twitter {
  background: #55acee;
  box-shadow: 0 2px #2795e9; }

#share li.share-twitter:hover {
  background: #83c3f3; }

#share li.share-facebook {
  background: #3b5998;
  box-shadow: 0 2px #2d4373; }

#share li.share-facebook:hover {
  background: #4c70ba; }

#share li.share-google {
  background: #dd4b39;
  box-shadow: 0 2px #c23321; }

#share li.share-google:hover {
  background: #e47365; }

#share li.share-hatena {
  background: #2c6ebd;
  box-shadow: 0 2px #225694; }

#share li.share-hatena:hover {
  background: #4888d4; }

#share li.share-pocket {
  background: #f13d53;
  box-shadow: 0 2px #e6152f; }

#share li.share-pocket:hover {
  background: #f26f7f; }

#share li.share-line {
  background: #00C300;
  box-shadow: 0 2px #009d00; }

#share li.share-line:hover {
  background: #2bd92b; }

#share a {
  display: block;
  height: 27px;
  padding-top: 6px;
  color: #ffffff;
  text-decoration: none; }

#share a:hover {
  text-decoration: none;
  color: #ffffff; }
