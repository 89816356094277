@import 'mixin';
@import 'reset';

body {
  font-family: "Hiragino Kaku Gothic ProN","メイリオ", sans-serif;
}

img {
  max-width: 100%;
  max-height: 100%;
}

.l-wrap {
  width: 640px;
  max-width: 100%;
  margin: 0 auto;
}

input.text {
  border: 1px solid blue;
  width: calc(100% - 30px);
  padding: 5px;
  margin: 0 15px;
  box-sizing: border-box;
  font-size: 16px;
}

.l-img {
  text-align: center;
  margin: 0 0 20px 0;
  p {
    margin: 20px 0;
  }
  a {
    color: blue;
  }
}

@import "/Users/uneazusa/sites/soccor/orejapan/src/sass/modules/_button.scss";
@import "/Users/uneazusa/sites/soccor/orejapan/src/sass/modules/_field.scss";
@import "/Users/uneazusa/sites/soccor/orejapan/src/sass/modules/_footer.scss";
@import "/Users/uneazusa/sites/soccor/orejapan/src/sass/modules/_formation.scss";
@import "/Users/uneazusa/sites/soccor/orejapan/src/sass/modules/_header.scss";
@import "/Users/uneazusa/sites/soccor/orejapan/src/sass/modules/_section.scss";
@import "/Users/uneazusa/sites/soccor/orejapan/src/sass/modules/_select.scss";
@import "/Users/uneazusa/sites/soccor/orejapan/src/sass/modules/_share.scss";


