.l-controller {
  // display: flex;
  // align-items: center;
  padding: 0 20px;
}

%button {
  display: block;
  width: 100%;
  position: relative;
  text-align: center;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
  text-align: center;
  padding: 30px 0;
  font-size: 16px;
  border-radius: 5px;
  margin: 0 0 10px 0;
  line-height: 1.4;
  &:before {
    position: absolute;
    top: 50%;
    font-size: 14px;
    transform: translate3D(0,-50%,0);
  }
  &.is-disabled {
    pointer-events: none;
    background: silver;
  }
  &.is-hide {
    display: none;
  }
}
%button-left {
  &:before {
    content: "◀︎";
    left: 20px;
  }
}
%button-right {
  &:before {
    content: "▶︎";
    right: 20px;
  }
}



.button-prev {
  @extend %button;
  @extend %button-left;
  background: gray;
}

.button-next {
  @extend %button;
  @extend %button-right;
  background: blue;
}

.button-restart {
  @extend %button;
  // @extend %button-left;
  // display: none;
  background: gray;
  font-size: 13px;
}

.button-img {
  // display: none;
  .submit {
    @extend %button;
    @extend %button-right;
    width: 100%;
    background: blue;
    font-size: 13px;
  }
  &.is-hide {
    display: none;
  }
}

.button-start {
  @extend %button;
  @extend %button-right;
  background: blue;
}



.l-controller-sns {
  display: flex;
  padding: 0 10px;
  a {

  }
}


.button-fb {
  @extend %button;
  width: 100%;
  background: #4267b2;
  margin: 0 5px;
}

.button-tw {
  @extend %button;
  width: 100%;
  background: #1b95e0;
  margin: 0 5px;
}