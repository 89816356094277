.l-section {
}

.section-heading {
  text-align: center;
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 15px;
}

.section-4231,
.section-361,
.section-352,
.section-4141,
.section-442 {
  display: none;
}

.section-inner {
  margin-bottom: 20px;
}