%player {
  position: absolute;
  width: 15px;
  height: 15px;
  background-size: 15px auto;
  background-position: 0 0;
  background-repeat: no-repeat;
}

%player_current {
  height: 35px;
  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 20px;
    padding-top: 15px;
    background-image: url(../img/formation/items/arrow.png);
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 15px auto;
  }
}

%name {
  width: 50px;
  height: 18px;
  background-size: 50px auto;
}

.field {
  position: relative;
  width: 300px;
  height: 200px;
  margin: 0 auto;
  background-image: url(../img/formation/items/field.jpg);
  background-size: 300px auto;
  background-repeat: no-repeat;

  .g {
    @extend %player;
    background-image: url(../img/formation/items/g.png);
    // background-image: url(../img/formation/items/g_current.png);
    top: 94px;
    left: 20px;
    &.is-current {
      @extend %player_current;
      background-image: url(../img/formation/items/g_current.png);
    }
  }
  .d {
    @extend %player;
    background-image: url(../img/formation/items/d.png);
    // background-image: url(../img/formation/items/d_current.png);
    &.is-current {
      @extend %player_current;
      background-image: url(../img/formation/items/d_current.png);
    }
  }
  .m {
    @extend %player;
    background-image: url(../img/formation/items/m.png);
    // background-image: url(../img/formation/items/m_current.png);

    &.is-current {
      @extend %player_current;
      background-image: url(../img/formation/items/m_current.png);
    }
  }
  .f {
    @extend %player;
    background-image: url(../img/formation/items/f.png);
    // background-image: url(../img/formation/items/f_current.png);
    &.is-current {
      @extend %player_current;
      background-image: url(../img/formation/items/f_current.png);
    }
  }

  $values: d_endo, d_makino, d_nagatomo, d_sakai_g, d_sakai_h, d_syoji, d_ueda, d_yoshida, f_muto, f_okazaki, f_osako, g_higashiguchi, g_kawashima, g_nakamura, m_haraguchi, m_hasebe, m_honda, m_inui, m_kagawa, m_oshima, m_shibazaki, m_usami, m_yamaguchi, else;
  @each $value in $values {
    .#{$value}{
      @extend %name;
      background-image: url(../img/player/#{$value}.png) !important;
      &.g {
        transform: translate3d(-25px,-5px,0);
      }
      &.d {
        transform: translate3d(-15px,-5px,0);
        .section-4231,
        .section-4141 & {
          transform: translate3d(-25px,-5px,0);
        }
      }
      &.m {
        transform: translate3d(-10px,-5px,0);
      }
      &.f {
        transform: translate3d(0px,-5px,0);
      }
    }
  }
  .section-442 & {
    // background-image: url(../img/formation/442_y.png);
    .d1 {
      top: 24px;
      left: 75px;
    }
    .d2 {
      top: 66px;
      left: 63px;
    }
    .d3 {
      top: 122px;
      left: 63px;
    }
    .d4 {
      top: 163px;
      left: 75px;
    }
    .m1 {
      top: 18px;
      left: 140px;
    }
    .m2 {
      top: 68px;
      left: 123px;
    }
    .m3 {
      top: 123px;
      left: 123px;
    }
    .m4 {
      top: 169px;
      left: 140px;
    }
    .f1 {
      top: 73px;
      left: 190px;
    }
    .f2 {
      top: 118px;
      left: 190px;
    }
  }

  .section-4141 & {
    // background-image: url(../img/formation/4141_y.png);
    .d1 {
      top: 24px;
      left: 65px;
    }
    .d2 {
      top: 66px;
      left: 63px;
    }
    .d3 {
      top: 122px;
      left: 63px;
    }
    .d4 {
      top: 163px;
      left: 65px;
    }
    .m1 {
      top: 94px;
      left: 110px;
    }
    .m2 {
      top: 18px;
      left: 177px;
    }
    .m3 {
      top: 63px;
      left: 163px;
    }
    .m4 {
      top: 128px;
      left: 163px;
    }
    .m5 {
      top: 169px;
      left: 177px;
    }
    .f1 {
      top: 94px;
      left: 202px;
    }
  }


  .section-4231 & {
    // background-image: url(../img/formation/4231_y.png);
    .d1 {
      top: 24px;
      left: 80px;
    }
    .d2 {
      top: 76px;
      left: 73px;
    }
    .d3 {
      top: 112px;
      left: 73px;
    }
    .d4 {
      top: 163px;
      left: 80px;
    }
    .m1 {
      top: 64px;
      left: 115px;
    }
    .m2 {
      top: 124px;
      left: 115px;
    }
    .m3 {
      top: 18px;
      left: 167px;
    }
    .m4 {
      top: 94px;
      left: 158px;
    }
    .m5 {
      top: 169px;
      left: 167px;
    }
    .f1 {
      top: 94px;
      left: 202px;
    }
  }

  .section-361 & {
    // background-image: url(../img/formation/361_y.png);
    .d1 {
      top: 42px;
      left: 62px;
    }
    .d2 {
      top: 95px;
      left: 62px;
    }
    .d3 {
      top: 145px;
      left: 62px;
    }
    .m1 {
      top: 67px;
      left: 104px;
    }
    .m2 {
      top: 122px;
      left: 104px;
    }
    .m3 {
      top: 25px;
      left: 138px;
    }
    .m4 {
      top: 160px;
      left: 138px;
    }
    .m5 {
      top: 60px;
      left: 167px;
    }
    .m6 {
      top: 120px;
      left: 167px;
    }
    .f1 {
      top: 94px;
      left: 202px;
    }
  }

  .section-361 & {
    // background-image: url(../img/formation/361_y.png);
    .d1 {
      top: 42px;
      left: 62px;
    }
    .d2 {
      top: 95px;
      left: 62px;
    }
    .d3 {
      top: 145px;
      left: 62px;
    }
    .m1 {
      top: 67px;
      left: 104px;
    }
    .m2 {
      top: 122px;
      left: 104px;
    }
    .m3 {
      top: 25px;
      left: 138px;
    }
    .m4 {
      top: 160px;
      left: 138px;
    }
    .m5 {
      top: 60px;
      left: 167px;
    }
    .m6 {
      top: 120px;
      left: 167px;
    }
    .f1 {
      top: 94px;
      left: 202px;
    }
  }

  .section-352 & {
    // background-image: url(../img/formation/352_y.png);
    .d1 {
      top: 42px;
      left: 62px;
    }
    .d2 {
      top: 95px;
      left: 62px;
    }
    .d3 {
      top: 145px;
      left: 62px;
    }
    .m1 {
      top: 67px;
      left: 104px;
    }
    .m2 {
      top: 122px;
      left: 104px;
    }
    .m3 {
      top: 25px;
      left: 138px;
    }
    .m4 {
      top: 160px;
      left: 138px;
    }
    .m5 {
      top: 95px;
      left: 167px;
    }
    .f1 {
      top: 58px;
      left: 202px;
    }
    .f2 {
      top: 130px;
      left: 202px;
    }
  }
}
